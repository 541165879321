"use client";
import { getAPIBaseURL } from "common/utils";
import CountryFlag from "components/common/country/CountryFlag";
import React, { useEffect, useState } from "react";
import { COUNTRY_LANG_LIST } from "../constants";
import LangList from "./LangList";

const getCountryFromLocale = (locale: string) => {
  for (const item of COUNTRY_LANG_LIST) {
    if (item.locale === locale) {
      return item;
    }
  }
  return COUNTRY_LANG_LIST[0];
};

function LanguageDropdown() {
  const [country, setCountry] = useState(getCountryFromLocale("en"));

  useEffect(() => {
    async function getLocaleAsync() {
      const url = `${getAPIBaseURL()}/api/user/locale`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        const country: any = getCountryFromLocale(data.locale);
        console.log(country, "country", data.locale);
        setCountry(country);
      }
    }
    getLocaleAsync();
  }, []);

  const handleLocaleChange = async (country: any) => {
    if (country) {
      const url = `${getAPIBaseURL()}/api/user/locale`;
      await fetch(url, {
        method: "POST",
        body: JSON.stringify({ locale: country.locale }),
      });
      setCountry(country);
    }
  };

  if (!country) {
    return null;
  }

  return (
    <>
      <div className="hs-dropdown relative inline-flex [--placement:top-left]">
        <button
          id="footer-language-dropdown"
          type="button"
          className="hs-dropdown-toggle py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border bg-white text-gray-700 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
        >
          <CountryFlag countryCode={country ? country.countryCode : "us"} />
          {country ? country.title : "Choose country"}
          <svg
            className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </button>
        <div
          className="hs-dropdown-menu w-40 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10 bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
          aria-labelledby="footer-language-dropdown"
        >
          <LangList
            onChange={handleLocaleChange}
            currentLocale={country.locale}
          />
        </div>
      </div>
    </>
  );
}

export default LanguageDropdown;
