import React from "react";

function CountryFlag({ countryCode }: { countryCode: string }) {
  switch (countryCode) {
    case "in":
      return (
        <img src="/static/images/flags/india.png" width={14} height={14} />
      );
    case "ru":
      return (
        <img src="/static/images/flags/russia.png" width={14} height={14} />
      );
    case "de":
      return (
        <img src="/static/images/flags/germany.png" width={14} height={14} />
      );
    case "us":
      return <img src="/static/images/flags/us.png" width={14} height={14} />;
    case "br":
      return (
        <img src="/static/images/flags/brazil.png" width={14} height={14} />
      );
    case "jp":
      return (
        <img src="/static/images/flags/japan.png" width={14} height={14} />
      );
    case "kr":
      return (
        <img
          src="/static/images/flags/south-korea.png"
          width={14}
          height={14}
        />
      );

    case "es":
      return (
        <img src="/static/images/flags/spain.png" width={14} height={14} />
      );
    case "id":
      return (
        <img src="/static/images/flags/indonesia.png" width={14} height={14} />
      );
    case "tr":
      return (
        <img src="/static/images/flags/turkey.png" width={14} height={14} />
      );
    default:
      return <></>;
  }
}

export default CountryFlag;
