"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FEATURE_LIST } from "./constants";

function Header() {
  return (
    <>
      {/* ========== HEADER ========== */}
      <header className="flex bg-black text-white flex-wrap sm:justify-start sm:flex-col z-50 w-full text-sm pb-2 sm:pb-0">
        {/* Topbar */}
        <div className="max-w-[85rem] mx-auto w-full px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-end gap-x-5 w-full py-2 sm:pt-2 sm:pb-0">
            <a className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-slate-500 text-sm dark:text-slate-400 dark:hover:text-slate-300">
              <svg
                className="flex-shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21.54 15H17a2 2 0 0 0-2 2v4.54" />
                <path d="M7 3.34V5a3 3 0 0 0 3 3v0a2 2 0 0 1 2 2v0c0 1.1.9 2 2 2v0a2 2 0 0 0 2-2v0c0-1.1.9-2 2-2h3.17" />
                <path d="M11 21.95V18a2 2 0 0 0-2-2v0a2 2 0 0 1-2-2v-1a2 2 0 0 0-2-2H2.05" />
                <circle cx={12} cy={12} r={10} />
              </svg>
              English (US)
            </a>

            <Link
              className="hidden md:inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-slate-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
              href="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              className="inline-flex justify-center items-center gap-2 font-medium text-slate-600 hover:text-slate-500 text-sm dark:text-slate-400 dark:hover:text-slate-300"
              href="/login"
            >
              Sign In
            </Link>
            <Link
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-blue-600 text-blue-600 hover:border-blue-500 hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-blue-500 dark:text-blue-500 dark:hover:text-blue-400 dark:hover:border-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="/get-started"
            >
              Get started
            </Link>
          </div>
        </div>
        {/* End Topbar */}
        <nav
          className="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center justify-between">
            <Link
              className="flex-none text-lg font-semibold dark:text-white"
              href="/"
              aria-label="Brand"
            >
              EnhanceTube
            </Link>
            <div className="sm:hidden">
              <button
                type="button"
                className="hs-collapse-toggle size-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-black text-white hover:bg-black-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="hs-collapse-open:hidden flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1={3} x2={21} y1={6} y2={6} />
                  <line x1={3} x2={21} y1={12} y2={12} />
                  <line x1={3} x2={21} y1={18} y2={18} />
                </svg>
                <svg
                  className="hs-collapse-open:block hidden flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
          >
            <div className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
              <div className="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:hover] py-3 md:py-6">
                <button
                  type="button"
                  className="flex items-center w-full text-white hover:text-gray-500 font-medium dark:text-gray-200 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  <span className="mr-2">Features</span>
                  <svg
                    className="flex-shrink-0 ms-2 size-2.5"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-80 hidden z-10 bg-white md:shadow-2xl rounded-lg py-2 md:p-2 dark:bg-gray-800 dark:divide-gray-700 before:absolute top-full before:-top-5 before:start-0 before:w-full before:h-5">
                  {FEATURE_LIST.map((feature, index) => {
                    return (
                      <div key={index}>
                        <Link
                          className="inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-lg hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                          href={feature.link}
                        >
                          <Image
                            width={20}
                            height={20}
                            alt="Icon"
                            className="w-5 h-5"
                            src={feature.icon}
                          />

                          <div className="grow">
                            <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">
                              {feature.title}
                            </span>
                            {feature.desc}
                          </div>
                        </Link>
                        {index != FEATURE_LIST.length - 1 && (
                          <div className="my-2 border-t border-gray-100 dark:border-gray-800" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <Link
                className="font-medium text-white hover:text-gray-500 sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
                href="/pricing"
              >
                Pricing
              </Link>
              <Link
                className="font-medium text-white hover:text-gray-500 sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
                href="/blog"
              >
                Blog
              </Link>
              <Link
                className="font-medium text-white hover:text-gray-500 sm:py-6 dark:text-gray-200 dark:hover:text-gray-400"
                href="/docs"
              >
                Docs
              </Link>

              <Link
                className="font-medium sm:py-6 text-white hover:text-gray-500"
                href="/contact"
                aria-current="page"
              >
                Contact
              </Link>
              <Link
                className="md:hidden inline-flex font-medium sm:py-6 text-white hover:text-gray-500"
                href="/dashboard"
              >
                Dashboard
              </Link>
            </div>
          </div>
        </nav>
      </header>
      {/* ========== END HEADER ========== */}
    </>
  );
}

export default Header;
