export const getAPIBaseURL = () => {
  const { isProd } = getNodeEnv();
  if (isProd) {
    return "https://enhancetube.com";
  }
  return "http://localhost:3000";
};

export const getBaseURL = () => {
  const { isProd } = getNodeEnv();
  return isProd ? "https://enhancetube.com" : "http://localhost:3000";
};

export const getNodeEnv = () => {
  const isDev = process.env.NODE_ENV === "development";
  const isProd = process.env.NODE_ENV === "production";

  return {
    isDev,
    isProd,
  };
};

export const getImageURL = (path: string) => {
  return `/static/images${path}`;
};

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
