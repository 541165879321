export const COUNTRY_LANG_LIST = [
  {
    title: "English (US)",
    countryCode: "us",
    locale: "en",
  },
  {
    title: "Hindi (IN)",
    countryCode: "in",
    locale: "hi",
  },
  {
    title: "Spanish",
    countryCode: "es",
    locale: "es",
  },
  {
    title: "Indonesian",
    countryCode: "id",
    locale: "id",
  },
  {
    title: "Japanese",
    countryCode: "jp",
    locale: "ja",
  },
  {
    title: "Portuguese",
    countryCode: "br",
    locale: "pt",
  },
];
