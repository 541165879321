import { getImageURL } from "common/utils";

export const FEATURE_LIST = [
  {
    title: "A/B Testing",
    desc: " Maximize Views, Engagement, and Conversions with Data-Driven Decisions.",
    link: "/tools/ab-testing",
    icon: getImageURL("/icons/beaker.svg"),
  },
  {
    title: "Video Dynamic Links",
    desc: "Reduce user friction with dynamic links",
    link: "/tools/video-dynamic-links",
    icon: getImageURL("/icons/link.svg"),
  },
  {
    title: "Advanced Video Embed",
    desc: "Embed your video with max controls.",
    link: "/tools/advanced-video-embed",
    icon: getImageURL("/icons/play.svg"),
  },
];
