"use client";
import classNames from "classnames";
import { getAPIBaseURL } from "common/utils";
import CountryFlag from "components/common/country/CountryFlag";
import React, { useEffect, useState } from "react";
import { COUNTRY_LANG_LIST } from "../constants";

function LangList({
  currentLocale,
  onChange,
}: {
  currentLocale: string;
  onChange: any;
}) {
  return (
    <>
      {COUNTRY_LANG_LIST.map((country, index) => {
        const isSelected = country.locale === currentLocale;
        return (
          <div key={index}>
            <a
              className={classNames(
                isSelected && "bg-gray-100",
                "flex w-full cursor-pointer items-center gap-x-2 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
              )}
              onClick={() => onChange(country)}
            >
              <CountryFlag countryCode={country.countryCode} />
              {country.title}
              {isSelected && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
              )}
            </a>
          </div>
        );
      })}
    </>
  );
}

export default LangList;
